import React, { useState } from 'react'
import styles from './ContactUs.module.css'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import {ReactComponent as MessageIcon} from 'assets/icons/messageIcon.svg'

import {ReactComponent as FacebookFooterIcon} from 'assets/icons/facebookIcon.svg'
import {ReactComponent as TwitterFooterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramFooterIcon} from 'assets/icons/instgramIcon.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactLoading from "react-loading";
import { axiosConfig } from 'utils/axiosConfig'
import { emailPattern } from 'utils/features'
import { toast } from 'react-toastify'
function ContactUs() {
  const {t} =useTranslation()
  const [data,setData]=useState({name:'',email:'',subject:'',message:'',phone:''})
  const [errors,setErros]=useState({}) 
  const [isLoading,setIsLoading]=useState(false)
  const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  function validateInputs(modififedData){
    let status =true
    let errors ={}
    if(!modififedData?.message){
        errors = {...errors,message:t('Message Is Required')}
        status=false
    }
    if(!modififedData?.subject){
        errors = {...errors,subject:t('Subject Is Required')}
        status=false
    }
    // if(!emailPattern.test(modififedData?.email)){
    //     errors = {...errors,email:t('Email Must Be valid Email')}
    //     status=false
    // }
    setErros(errors)
    return status
}

function handleChange(value , name){
    let modififedData = {...data}
    modififedData[name] = value
    if(isSubmittedBefore){
        validateInputs(modififedData)
    }
    setData(modififedData)
}

function sendRequest(){
    setIsSuccessOpen(false)
    setIsSubmittedBefore(true)
    if(!validateInputs(data)){
        return
    }
    let formData = new FormData()
    setIsLoading(true)
    formData.append('name','--')
    formData.append('email','--')
    formData.append('message',data?.message)
    formData.append('phone',`--`)
    formData.append('subject',data?.subject)
    
 
    axiosConfig.post('/contact-us/create-contact-us',formData).then(res=>{
        setIsLoading(false)
        setData({name:'---',email:'---',message:'',phone:'---',subject:''})
        toast.success(t('Contact Us Request Is Sent Successfully'))
        setIsSuccessOpen(true)
    }).catch(err=>{
        setIsLoading(false)
        toast.error(t('Something Went Wrong'))
    })
}
  return (
    <section className={styles['contact-us']}>
        <div className={styles['contact-us__wrapper']}>
            <div className={styles['contact-us__content-wrapper']}>
                <div className={styles['contact-us__content']}>
                  <h2 className={styles['contact-us__title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('technical_support_department')}</h2>
                  <p className={styles['contact-us__para-1']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('you_can_always_contact')}  </p>
                  <p className={styles['contact-us__para-2']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('contact_us_through')}</p>
                  <div onClick={()=>{window.open(`tel:+971562222557`, '_self')}} className={styles['contact-us__para-3']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                    <PhoneIcon/>
                    +971 56 222 2557
                  </div>
                  <p className={styles['contact-us__para-4']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>24/7</p>
                  <p className={styles['contact-us__para-5']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('during_the_month')}</p>
                  <p className={styles['contact-us__para-6']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('send_a_message')}</p>
                  <div className={styles['contact-us__para-8']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                  Tobe@gmail.com
                    <MessageIcon/>
                  </div>
                  <p className={styles['contact-us__para-7']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('contact_us_via')}</p>
                  <ul className={styles["footer__copyright-list"]} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <FacebookFooterIcon className={styles["footer__copyright-social"]}></FacebookFooterIcon>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <TwitterFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                    <li className={styles["footer__copyright-item"]}>
                        <Link to='/' className={styles["footer__copyright-link"]}>
                            <InstagramFooterIcon className={styles["footer__copyright-social"]}/>
                        </Link>
                    </li>
                  </ul>
                </div>
            </div>
            <div className={styles['contact-us__form-wrapper']}>
              <h2 className={styles['contact-us__form-title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('connect_with_us')}</h2>
              <p className={styles['contact-us__form-description']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('department_of_receiving')}</p>
              <form className={styles['contact-us__form']}>
                <div className='w-100'>
                  <input 
                    type='text' 
                    className={styles['contact-us__form-input']} 
                    placeholder={t('Subject')} 
                    data-aos='fade-up' 
                    data-aos-duration='800' 
                    data-aos-delay='100' 
                    data-aos-once='true'
                    value={data?.subject}
                    name='subject'
                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}
                  />
                  {errors?.subject&& <p className={styles['contact-us__content-input--error']}>{errors?.subject}</p>}
                </div>
                <div className='w-100'>
                  <textarea 
                    className={styles['contact-us__form-input--textarea']} 
                    placeholder={t('message')} 
                    data-aos='fade-up' 
                    data-aos-duration='800' 
                    data-aos-delay='100' 
                    data-aos-once='true'
                    value={data?.message}
                    name='message'
                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}></textarea>
                  {errors?.message&& <p className={styles['contact-us__content-input--error']}>{errors?.message}</p>}
                </div>
                <button 
                className={styles['contact-us__form-button']} 
                onClick={sendRequest}
                type='button'
                data-aos='fade-up' 
                data-aos-duration='800' 
                data-aos-delay='100' 
                data-aos-once='true'>
                  {
                    !isLoading?t('send')
                    :<ReactLoading type={"spin"} color={'#ffffff'} height={40} width={40} />
                  }
                </button>
              </form>
            </div>
        </div>
    </section>
  )
}

export default ContactUs